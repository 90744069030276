










import Vue from 'vue';
import Component from 'vue-class-component'
import SygniRoundedButton from '@/components/buttons/SygniRoundedButton.vue';
import { Prop } from 'vue-property-decorator';

@Component({
  components: { SygniRoundedButton },
})
export default class SimplePostBox extends Vue {
  @Prop({default: 'normal'}) size: 'normal' | 'sm';
  @Prop() isLoading!: boolean;
  @Prop() thumbnail?: string;
  @Prop() label!: string;
  @Prop() title!: string;
}

var render = function () {
  var _vm$packages;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "welcome-fund-manager"
  }, [((_vm$packages = _vm.packages) === null || _vm$packages === void 0 ? void 0 : _vm$packages.length) > 0 ? _c('sygni-container-title', [_vm._v("Our plans")]) : _vm._e(), _c('div', {
    staticClass: "plans-container"
  }, _vm._l(_vm.packages, function (pack) {
    return _c('package-card', {
      key: pack.id,
      attrs: {
        "pack": pack
      },
      on: {
        "choosePackage": function choosePackage($event) {
          return _vm.chooseProduct($event);
        }
      }
    });
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
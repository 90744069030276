









import Vue from 'vue'
import Component from 'vue-class-component'
import SygniCard from "@/components/layout/SygniCard.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import {mapState} from "vuex";
import {Contexts, UserContext} from "@/modules/genprox/models/User";
import {Package} from "@/modules/genprox/models/Package";
import PackageCard from "@/modules/genprox/components/welcome/PackageCard.vue";
import ContextHelper from "@/modules/shared/utils/ContextHelper";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";

@Component({
  components: {SygniContainerTitle, PackageCard, SygniRoundedButton, SygniCard},
  computed: {
    ...mapState('genprox', {
      packages: (state: any) => state.packages,
      activeUserContext: (state: any) => state.activeUserData.context
    })
  }
})
export default class Packages extends Vue {

  packages!: Array<Package>;
  activeUserContext!: UserContext;

  chooseProduct(product: Package): void {
    if  ( this.canAcceptPackage(product.requiredContext) ){
        this.$router.push({name: 'accept-package-by-id',params:{ id: product.id}});
    } else {
      this.$router.push({name: 'select-fund', query: {id: product.id}});
    }
  }

  canAcceptPackage(requiredContext: Contexts){
    const canAcceptByFund = requiredContext === 'fund' &&
    ( ContextHelper.isActiveContextFund || ContextHelper.isActiveContextFundManager);
    const canAcceptByCompany = requiredContext === 'company' &&
        ( ContextHelper.isActiveContextCompany || ContextHelper.isActiveContextCompany);
    return canAcceptByCompany || canAcceptByFund || requiredContext === null;
  }

}







































import Vue from 'vue'
import Component from 'vue-class-component'
import SygniCard from "@/components/layout/SygniCard.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import { mapState } from "vuex";
import { Package } from "@/modules/genprox/models/Package";
import { Prop } from "vue-property-decorator";
import { UserContext } from '@/modules/genprox/models/User';

@Component({
  components: { SygniRoundedButton, SygniCard },
  computed: {
    ...mapState('genprox', {
      packages: (state: any) => state.packages,
      activeUserContext: (state: any) => state.activeUserData.context
    })
  }

})
export default class PackageCard extends Vue {
  @Prop() pack!: Package;
  requestSended: boolean = false;
  lePackageId: string = '';
  activeUserContext!: UserContext;

  get paymentPlan(): string {
    switch (this.pack?.billingPeriod) {
      case "Annually":
        return "annually";
      case "Monthly":
        return "monthly";
      case "Quarterly":
        return "quarterly";
      default:
        return '';
    }
  }

  buttonClick(): void {
    this.$emit('choosePackage', this.pack);
  }

  async sendRequest() {
    await this.$store.dispatch('genprox/sendPackageRequest', this.pack.id);
    this.$notify({
      duration: 5000,
      type: 'success',
      title: `Request contact for ${this.pack.name}`,
      text: 'Your request has been sent. We will contact you in next 24h.'
    });
    this.requestSended = true;
    this.$cookies.set(this.lePackageId, true, 60 * 60 * 24);
  }

  mounted() {
    this.requestSended = Boolean(this.$cookies.get(this.lePackageId));
  }

  beforeMount() {
    const legalEntityId: string = this.activeUserContext.id;
    this.lePackageId = `PackageRequested:${legalEntityId}${this.pack.id}`;
  }
}

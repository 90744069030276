
















import Vue from 'vue'
import Component from 'vue-class-component'
import SygniCard from "@/components/layout/SygniCard.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";

@Component({
  components: {SygniRoundedButton, SygniCard},
})
export default class Packages extends Vue {

  emitAuthorizeDataClick(){
    this.$emit('goToAuthorize');
  }

}

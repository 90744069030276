var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['simple-post-box', _vm.isLoading ? 'simple-post-box--loading' : '', _vm.size == 'sm' ? 'simple-post-box--sm' : ''],
    on: {
      "click": function click($event) {
        return _vm.$emit('click');
      }
    }
  }, [_vm.thumbnail ? _c('div', {
    staticClass: "simple-post-box__featured-image"
  }, [_c('img', {
    attrs: {
      "src": _vm.thumbnail,
      "alt": _vm.title
    }
  })]) : _vm._e(), _c('p', {
    staticClass: "simple-post-box__label"
  }, [_vm._v(_vm._s(_vm.label))]), _c('h2', {
    staticClass: "simple-post-box__title"
  }, [_vm._v(_vm._s(_vm.title))])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
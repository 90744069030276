





































import Vue from 'vue'
import Component from 'vue-class-component'
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import FreeToolsBox from "@/modules/genprox/components/welcome/FreeToolsBox.vue"
import {Prop} from "vue-property-decorator";

@Component({
  components: {SygniContainerTitle, FreeToolsBox},
})
export default class FreeTools extends Vue {
  @Prop() title!: String;

  setLoading(val: Boolean) {
    this.$emit('setLoading', val)
  }
}

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "free-tools"
  }, [_c('sygni-container-title', [_vm._v(_vm._s(_vm.title))]), _c('div', {
    staticClass: "free-tools__container"
  }, [_c('FreeToolsBox', {
    attrs: {
      "description": 'Upload your DATMAN or DATIF XML files for KNF reporting to review their content in readable form.',
      "title": 'XML Reader for AIF Regulatory Reporting',
      "href": '/fund/reg-reporting/knf-xml-preview',
      "uploadId": 'fileUpload1',
      "urlType": 'knf'
    },
    on: {
      "setLoading": _vm.setLoading
    }
  }), _c('FreeToolsBox', {
    attrs: {
      "description": 'Upload your Financial Statement in XML format to review its content in readable form.',
      "href": '/fund/reg-reporting/xml-preview',
      "title": 'XML Reader for Financial Statements',
      "uploadId": 'fileUpload2',
      "urlType": 'xml'
    },
    on: {
      "setLoading": _vm.setLoading
    }
  }), _vm._m(0)], 1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "free-tools__box"
  }, [_c('div', {
    staticClass: "free-tools-box"
  }, [_c('div', {
    staticClass: "free-tools-box__icon"
  }, [_c('img', {
    attrs: {
      "alt": "",
      "src": require("@/assets/icons/desktop.svg")
    }
  })]), _c('p', {
    staticClass: "free-tools-box__text"
  }, [_vm._v("More tools coming soon...")])])]);
}]

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "free-tools__box"
  }, [_c('div', {
    staticClass: "xml-reader"
  }, [_c('div', {
    staticClass: "xml-reader__inner"
  }, [this.href ? _c('router-link', {
    staticClass: "xml-reader__header xml-reader__header--link",
    attrs: {
      "to": this.href
    }
  }, [_vm._v(_vm._s(_vm.title))]) : _c('p', {
    staticClass: "xml-reader__header"
  }, [_vm._v(_vm._s(_vm.title))]), _c('p', {
    staticClass: "xml-reader__desc"
  }, [_vm._v(_vm._s(_vm.description))]), _c('div', {
    staticClass: "xml-reader__actions"
  }, [_c('div', {
    class: ['drag-button', _vm.dragActive ? 'drag-button--active' : ''],
    on: {
      "dragenter": function dragenter($event) {
        _vm.dragActive = true;
      },
      "dragleave": function dragleave($event) {
        _vm.dragActive = false;
      },
      "dragover": _vm.dragFileOver,
      "drop": function drop($event) {
        return _vm.uploadXmlFile($event, $event.dataTransfer.files);
      }
    }
  }, [_vm._m(0), _c('p', {
    staticClass: "drag-button__text"
  }, [_vm._v("Drag and drop your file")])]), _c('label', {
    staticClass: "upload-button",
    attrs: {
      "for": _vm.uploadId
    }
  }, [_vm._v(" Upload from computer "), _c('input', {
    ref: "fileInput",
    attrs: {
      "id": _vm.uploadId,
      "accept": "text/xml",
      "type": "file"
    },
    on: {
      "change": function change($event) {
        return _vm.uploadXmlFile($event, $event.target.files);
      }
    }
  })]), _c('p', {
    staticClass: "xml-reader__signature"
  }, [_vm._v("File format: xml / max 5mb")])])], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "drag-button__icon"
  }, [_c('img', {
    attrs: {
      "alt": "",
      "src": require("@/assets/icons/xml.svg")
    }
  })]);
}]

export { render, staticRenderFns }
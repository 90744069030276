var render = function () {
  var _vm$pack, _vm$pack2, _vm$pack3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.pack ? _c('sygni-card', {
    staticClass: "package",
    class: {
      'package--active': _vm.pack.active
    }
  }, [_c('div', {
    class: ['package__prefix', !(((_vm$pack = _vm.pack) === null || _vm$pack === void 0 ? void 0 : _vm$pack.type) === 'on-demand' && _vm.pack.prefix) ? 'hidden' : '']
  }, [_vm._v(_vm._s(_vm.pack.prefix || 'On demand'))]), _c('div', {
    staticClass: "package__name"
  }, [_vm._v(_vm._s(_vm.pack.name))]), _c('div', {
    staticClass: "package__description",
    domProps: {
      "innerHTML": _vm._s(_vm.pack.description)
    }
  }), _vm.pack.www ? _c('div', {
    staticClass: "package__read-more"
  }, [_c('a', {
    attrs: {
      "href": _vm.pack.www,
      "target": "_blank"
    }
  }, [_vm._v("Read more")])]) : _vm._e(), _c('div', {
    staticClass: "package__price"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(" PRICING ")]), _c('div', {
    staticClass: "price"
  }, [_vm._v(" " + _vm._s(_vm._f("thousandSeparator")(_vm.pack.price.value)) + " " + _vm._s(_vm.pack.price.currency) + " "), ((_vm$pack2 = _vm.pack) === null || _vm$pack2 === void 0 ? void 0 : _vm$pack2.plan) !== 'One-off' ? _c('span', [_vm._v("/ " + _vm._s(_vm.paymentPlan))]) : _vm._e()])]), ((_vm$pack3 = _vm.pack) === null || _vm$pack3 === void 0 ? void 0 : _vm$pack3.type) === 'on-demand' ? _c('sygni-rounded-button', {
    class: {
      'filled': !_vm.pack.active,
      'gn-primary': !_vm.pack.active,
      'custom': _vm.pack.active,
      'active': _vm.pack.active
    },
    attrs: {
      "disabled": _vm.requestSended
    },
    on: {
      "click": function click($event) {
        return _vm.sendRequest();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.requestSended ? 'Requested' : _vm.pack.active ? 'Active' : 'Request Contact') + " ")]) : _c('sygni-rounded-button', {
    class: {
      'filled': !_vm.pack.active,
      'gn-primary': !_vm.pack.active,
      'custom': _vm.pack.active,
      'active': _vm.pack.active
    },
    on: {
      "click": function click($event) {
        return _vm.buttonClick();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.pack.active ? 'Active' : 'Choose plan') + " ")])], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }
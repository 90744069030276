





























import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class FreeToolsBox extends Vue {
  @Prop() readonly title: String;
  @Prop() readonly description: String;
  @Prop() readonly uploadId: String;
  @Prop() readonly urlType!: String;
  @Prop() readonly href!: String;

  dragActive: boolean = false;
  type: String = '';

  async uploadXmlFile(e: Event, files: string | any[]) {
    e.preventDefault();
    this.dragActive = false;
    this.$emit('setLoading', true);

    if (files.length) {
      if (files.length == 1) {
        const file = files[0];
        const formData = new FormData();
        const fileType = file.name.split('.').pop()
        formData.append('file', file);
        const xadesCheck = this.urlType === 'xml' ? fileType.toLowerCase() != 'xades' : true;
        if (file.type !== "text/xml" && xadesCheck) {
          this.$emit('setLoading', false);
          this.$notify({
            duration: 2500,
            type: 'error',
            title: 'Error',
            text: 'Wrong file format'
          });
        } else {
          try {
            const { urlType } = this;
            let route, apiQuery;

            if (urlType === 'knf') {
              route = 'fund-reg-xml-preview';
              apiQuery = '/regulatory-reporting/knf-report-xml/upload';
            } else if (urlType == 'xml') {
              route = 'fund-xml-preview';
              apiQuery = '/regulatory-reporting/sf-report-xml/upload';
            }

            const data = await this.$store.dispatch('regReporting/uploadXmlFile', { file, apiQuery });
            this.$router.push({ name: route, query: { id: data.id } })
          } catch (e) {
            this.$emit('setLoading', false);
            this.$notify({
              duration: 2500,
              type: 'error',
              title: 'Error',
              text: 'Something went wrong, please try again later.'
            });
          }
        }
      } else {
        this.$emit('setLoading', false);
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Multiple files',
          text: 'You can select only single file to upload.'
        });
      }
    }
  }

  dragFileOver(e: Event) {
    e.preventDefault();
  }
}
